(($) => {

  const strategyGalleries = Array.from(document.querySelectorAll('.component-strategy-gallery'));

  strategyGalleries.forEach((strat,s) => {

    const groups = Array.from(strat.querySelectorAll(".strategy__groups .strategy__group"));
    const nav = strat.querySelector(".strategies__nav");
    const bg = strat.querySelector(".component__background");
    const navGroups = Array.from(strat.querySelectorAll(".strategies__nav a"));
    let activeGroup = null;
    let bounds = strat.getBoundingClientRect();

    const getGroup = groupId => groups.find(g => g.dataset.group === groupId);
    const getNavGroup = groupId => navGroups.find(g => g.dataset.rel === groupId);

    const setActiveGroup = (groupId,on,scroll) => {
      if (activeGroup && groupId !== activeGroup) setActiveGroup(activeGroup,false,false);
      const targetGroup = getGroup(groupId);
      if (on) {
        activeGroup = groupId;
        targetGroup.classList.add("active");
        bg.style.backgroundImage = `url("${targetGroup.dataset.background}")`;
        //history.pushState({},'',`#${groupId}`);
        //window.dispatchEvent(new CustomEvent("scroll"));
        // if (scroll) {
        //   const top = galleryItems.find(item => item.dataset.group === groupId).offsetTop;
        //   const scrollHeight = galleryScroll.getBoundingClientRect().height;
        //   $(galleryScroll).animate({
        //     scrollTop:top - (window.innerHeight * .29)
        //   },1000);
        // }
      } else {
        activeGroup = null;
        //targetGroup.classList.remove("active");
      }
      setActiveGroupNav(groupId,on);
    }

    const setActiveGroupNav = (groupId,on) => {
      const targetNavGroup = getNavGroup(groupId);
      if (on) {
        targetNavGroup.classList.add("active");
      } else {
        targetNavGroup.classList.remove("active");
      }
    }

//     const setGroupNavHandlers = () => {
//       navGroups.forEach(n => n.addEventListener("click",() => {
//         if (activeGroup !== n.dataset.rel) setActiveGroup(n.dataset.rel,true,true);
//       }))
// 
//       window.addEventListener("hashchange",() => {
//         const hash = location.hash.substring(1,location.hash.length);
//         const navGroup = getNavGroup(hash);
//         if (navGroup && activeGroup !== hash) setActiveGroup(hash,true,true);
//       });
//     }

    //setGroupNavHandlers();

    const getActiveGroupFromScrollPos = (scrollPos) => {
      let i = groups.length - 1;
      do {    
        activeItem = groups[i];
        i--;
      } while (i >= 0 && activeItem.getBoundingClientRect().top > scrollPos);
      return activeItem.dataset.group;
    }

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollBottom = scrollTop + window.innerHeight;
      const offsetTop = bounds.top + 61;
      const offsetBottom = bounds.bottom + 1;
      if (scrollTop >= offsetTop && scrollBottom <= offsetBottom) {
        const group = getActiveGroupFromScrollPos((scrollBottom-scrollTop)/2);
        if (group !== activeGroup) setActiveGroup(group,true,false);
        bg.classList.add("sticky");
        nav.classList.add("sticky");
        bg.classList.remove("end");
        nav.classList.remove("end");
      } else {
        bg.classList.remove("sticky");
        nav.classList.remove("sticky");
        if (scrollBottom > offsetBottom) {
          bg.classList.add("end");
          nav.classList.add("end");
        } else {
          bg.classList.remove("end");
          nav.classList.remove("end");         
        }
      }
    }

    const setGalleryScrollHandlers = () => {
      document.body.addEventListener("resize",() => {
        let bounds = strat.getBoundingClientRect();
      });

      window.addEventListener("scroll",handleScroll);
    }

    setGalleryScrollHandlers();

    groups.forEach((group,g) => {

      //Preload background images
      const preload = document.createElement("link");
      preload.rel = "preload";
      preload.as = "image";
      preload.href= group.dataset.background;
      document.body.appendChild(preload);

      const gallery = group.querySelector('.gallery-strategy');
      const type = gallery.dataset.type;
      const full_posts = group.querySelector(`.gallery__full-posts`);
      const screen = full_posts.querySelector('.gallery__screen');
      const nav_jump = document.querySelector('.nav-jump');
      const galleryItems = Array.from(gallery.querySelectorAll('.gallery__item'));

      screen.addEventListener("click",() => {
        if (active) setActivePost(active,false);
      })
      nav_jump.addEventListener("click",() => {
        if (active) setActivePost(active,false);
      })

      let active = undefined;
      const posts = {};

      const setActivePost = (id,on) => {
        if (posts[active]) posts[active].full.classList.remove("active");
        active = on ? id : null;
        if (on) {
          posts[id].full.classList.add("active");
          screen.classList.add("active");
          document.documentElement.classList.add("gallery-card-active");
        } else {
          posts[id].full.classList.remove("active");
          screen.classList.remove("active");
          document.documentElement.classList.remove("gallery-card-active");
        }
        
      }

      document.addEventListener("keydown",(e) => {
        e = e || window.event;
        var isEscape = false;
        if ("key" in e) {
            isEscape = (e.key === "Escape" || e.key === "Esc");
        } else {
            isEscape = (e.keyCode === 27);
        }
        if (isEscape && posts[active]) {
          setActivePost(active,false);
        }
      })
      

      const setCardHandlers = () => {
        galleryItems.forEach((preview,p) => {
          const id = preview.dataset.post;
          const full = full_posts.querySelector(`.post__full[data-post="${id}"]`);
          posts[id] = {
            id:id,
            preview:preview,
            full:full
          };
          preview.addEventListener("click",() => {
            setActivePost(id,true);
          })
          full.querySelector(".card__close").addEventListener("click",() => {
            setActivePost(id,false);
          })
        })
      }

      setCardHandlers();

      const controls = Array.from(full_posts.querySelectorAll(".card__nav"));
      controls.forEach(control => {
        const prev = control.querySelector(".nav-prev a");
        const prevId = prev.dataset.rel;
        prev.addEventListener("click",(e) => {
          e.preventDefault();
          setActivePost(prevId,true);
        })
        
        const next = control.querySelector(".nav-next a");
        const nextId = next.dataset.rel;
        next.addEventListener("click",(e) => {
           e.preventDefault();
          setActivePost(nextId,true);
        })
      })

    })
  })


})(jQuery)